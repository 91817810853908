import ClassicFieldSettingsApi from './classic'
import ResponsiveFieldSettingsApi from './responsive'

export const SPACE_BETWEEN_FIELDS = 32
export type FieldsApi = ClassicFieldSettingsApi | ResponsiveFieldSettingsApi

export default function (boundEditorSDK, coreApi, remoteApi, helpers) {
  switch (coreApi.editorType) {
    case 'RESPONSIVE':
      return new ResponsiveFieldSettingsApi(boundEditorSDK, coreApi, remoteApi, helpers)
    case 'ADI':
    default:
      return new ClassicFieldSettingsApi(boundEditorSDK, coreApi, remoteApi, helpers)
  }
}
