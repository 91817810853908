import { ROLE_MESSAGE, THANK_YOU_STEP_ROLE } from '../../../constants/roles'
import translations from '../../../utils/translations'
import { undoable } from '../decorators'
import { fetchHiddenMessage } from '../services/form-service'
import BaseFieldSettingsApi from './base'
import { FormPreset } from '../../../constants/form-types'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationFormMessageStructure from '../../../assets/presets/registration-form-message.json'

export default class ClassicFieldSettingsApi extends BaseFieldSettingsApi {
  constructor(boundEditorSDK, coreApi, remoteApi, helpers) {
    super(boundEditorSDK, coreApi, remoteApi, helpers)
  }

  @undoable()
  public async restoreHiddenMessage(formComponentRef: ComponentRef, newMessage?: string) {
    if (await this.isComponentExistsByRole(formComponentRef, ROLE_MESSAGE)) {
      return
    }
    return this._restoreHiddenMessage(formComponentRef, newMessage)
  }  

  private async _restoreHiddenMessage(formComponentRef: ComponentRef, newMessage?: string) {
    const isRegistrationForm = await this.coreApi.isRegistrationForm(formComponentRef)

    const createMessage = async (preset: FormPreset, locale, formLayout) => {
      // TODO: Change this to use getTranslationByPlugin and remove isRegistrationForm statement
      const fallbackSchema = isRegistrationForm
        ? registrationFormMessageStructure
        : hiddenMessageStructure
      const fallbackMessage = isRegistrationForm
        ? translations.t('settings.errorMessage.registrationForm')
        : translations.t('settings.successMessage.default')

      return fetchHiddenMessage(this.ravenInstance)(
        {
          role: ROLE_MESSAGE,
          newMessage: newMessage || fallbackMessage,
          fallbackSchema,
          formLayout,
          preset,
          locale,
        },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )
    }

    const parentComponentRef = await this._getContainerToRestoreMessage(
      formComponentRef,
      THANK_YOU_STEP_ROLE,
    )

    await this.restoreCrucialElement(formComponentRef, createMessage, parentComponentRef)

    if (isRegistrationForm) {
      await this.reLayoutErrorMessage(parentComponentRef)
    } else {
      await this.reLayoutHiddenMessage(parentComponentRef, ROLE_MESSAGE)
    }

    await this.updateFormHeightIfNeeded(parentComponentRef)
  }
}
