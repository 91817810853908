import { FormPreset } from '@wix/forms-common'
import _ from 'lodash'
import { ROLE_MESSAGE } from '../../../constants/roles'
import translations from '../../../utils/translations'
import { undoable } from '../decorators'
import { getItemLayoutData } from '../layout/responsive-utils'
import { fetchHiddenMessage } from '../services/form-service'
import BaseFieldSettingsApi from './base'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'

export default class ResponsiveFieldSettingsApi extends BaseFieldSettingsApi {
  constructor(boundEditorSDK, coreApi, remoteApi, helpers) {
    super(boundEditorSDK, coreApi, remoteApi, helpers)
  }

  @undoable()
  public async restoreHiddenMessage(formComponentRef: ComponentRef, newMessage?: string) {
    if (await this.isComponentExistsByRole(formComponentRef, ROLE_MESSAGE)) {
      return
    }
    return this._restoreHiddenMessage(formComponentRef, newMessage)
  }

  private async _restoreHiddenMessage(
    formComponentRef: ComponentRef,
    newMessage?: string,
  ) {
    const childLayouts = await this.coreApi.layout.getChildrenResponsiveLayouts(formComponentRef)

    const createMessage = (preset: FormPreset, locale, formLayout) =>
      fetchHiddenMessage(this.ravenInstance)(
        {
          role: ROLE_MESSAGE,
          newMessage: newMessage || translations.t('settings.successMessage.default'),
          fallbackSchema: hiddenMessageStructure,
          formLayout,
          preset,
          locale,
          itemLayoutType: childLayouts.itemLayoutType,
          layoutData: getItemLayoutData(
            _.get(_.last(childLayouts.layouts), 'layoutResponsive'),
            true,
          ),
        },
        (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
      )        
        
    return this.restoreCrucialElement(formComponentRef, createMessage)
  }
}
